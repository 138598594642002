body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body.dark-mode {
  background-color: #2c2929;
  color: #dadada;
}

@font-face {
  font-family: 'Bangers';
  src: local('Bangers'), url('./Bangers-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'LatinoType';
  src: local('LatinoType'), url('./PatuaOne-Regular.ttf') format('truetype');
}

body {
  font-family: 'Bangers', sans-serif;
}

.rbc-timeslot-group {
  min-height: 70px;
}

.rbc-day-slot .rbc-time-slot {
  border-top: 1px solid darkgrey;
}

.rbc-current-time-indicator {
  background-color: red;
}
